<template>
  <div class="office-equipments-manage">
      <div class="equipments">
          <div class="search">
            <el-input v-model="search" placeholder="חפש ציוד משרדי">
                <template #prefix>
                    <i class="el-input__icon el-icon-search"></i>
                </template>
            </el-input>
          </div>
          <div class="add-equipment">
              <el-button @click="handle_add_new_equipment" type="success" icon="el-icon-plus" circle></el-button>
          </div>
        <template v-for="(equipment) in filter_by_search" :key="equipment.uid">
            <div class="equipment">
                <Equipment @modify="modify_equipment" @delete="delete_equipment" :equipment="equipment" />
            </div>
        </template>
         
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Equipment from '../../../components/Admin/Shivuk_Rosman/equipments/Equipment.vue'
import { computed, onMounted } from '@vue/runtime-core'
import {get_equipments_from_db,add_new_equipment_to_db} from '../../../Methods/rosman_shivuk_funcs'
import Swal from 'sweetalert2'
export default {
    components:{Equipment},
    setup(){
        const search = ref('')
        
        const equipments = ref([])

        const handle_add_new_equipment = ()=>{
            Swal.fire({
                icon: 'question',
                title: 'שם הציוד החדש',
                input: 'text',
                confirmButtonText: 'עדכן',
                cancelButtonText: 'בטל',
                showCancelButton: true,
                denyButtonText: 'בטל עדכון',
                showDenyButton: true
            }).then(async res=>{
                if(res.isConfirmed && res.value){
                    const new_equipment =  await add_new_equipment_to_db(res.value)
                    equipments.value.push(new_equipment)
                }
            })
        }

        const delete_equipment=(equipment_uid)=>{
            const index = equipments.value.findIndex(_equipment=>_equipment.uid == equipment_uid)
            if(index!=-1){
                equipments.value.splice(index,1)
            }
        }
        const modify_equipment=(equipment)=>{
            const index = equipments.value.findIndex(_equipment=>_equipment.uid == equipment.uid)
            if(index!=-1){
                equipments.value[index] = equipment
            }
        }

        const filter_by_search = ref(computed(()=>{
            if(search.value=='') return equipments.value
            return equipments.value.filter(equipment=>{
                if(equipment.name.includes(search.value)){
                    return equipment
                }
            })
        }))
        
        onMounted(async()=>{
           equipments.value =  await get_equipments_from_db()
        })
       
        return{
          modify_equipment,
          delete_equipment,
          handle_add_new_equipment,
          search,
          filter_by_search,
          equipments
        }
    }
}
</script>

<style scoped>
    .office-equipments-manage{
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
    }
    .equipments{
        width: 375px;
        height: 100%;
        margin-bottom: 5px;
        text-align: center;
        overflow:hidden;
        overflow-y: auto;
    }
    .equipment{
        margin-bottom: 5px;
        width: 100%;
        height: 50px;
    }
    .add-equipment{
        width: 100%;
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .search{
        width: 100%;
        height: 40px;
    }
</style>